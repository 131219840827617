import React from "react"

  function FormEmbed({ title, content, form }){
  return (
    <section className="content white-background centered-form-holder">
      <div class="forty-spacer"></div>

      {title &&
        <div className="centered-title-holder">
          <h3>
            {title}
          </h3>
        </div>
      }

      {content && 
        <div className="centered-intro-text-holder" dangerouslySetInnerHTML={{__html: content}}/>
      }

      <h3 dangerouslySetInnerHTML={{ __html: form }}/>

      <div className="sixty-spacer"></div>
    </section>
  )
  }


export default FormEmbed
